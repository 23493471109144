.admin-root {
	display: flex;
}

.admin-menu {
	flex-basis: 240px;
	flex-shrink: 0;

	a {
		display: block;
		text-decoration: none;
		height: 30px;
		line-height: 29px;

		&.active {
			pointer-events: none;
			background: #eee;
		}
	}
}

.admin-content {
	flex-grow: 1;

	.block-header {
		font-weight: bold;
		margin-bottom: 15px;
	}

}
