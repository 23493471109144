.sc-panel-wrapper {
	position: absolute;
	z-index: var(--panel-z, 1000);
	//backdrop-filter: blur(20px);
	//-webkit-backdrop-filter: blur(20px);
	color: var(--text);
	padding: 0;
	border-radius: 4px;
	box-shadow: 0 0 10px rgba(#000, 0.1);
	cursor: default;

	//-webkit-backface-visibility: hidden;
	//-webkit-transform: translate3d(0,0,0);
	//-webkit-transform: translateZ(0);
	//backface-visibility: hidden;
	//transform: translate3d(0,0,0);
	//transform: translateZ(0);

	& > .header {
		background: var(--dark);
		color: #fff;
		height: 40px;
		line-height: 40px;
		padding: 0 15px;
		margin: 0;
		//border-top-left-radius: 5px;
		//border-top-right-radius: 5px;
		font-size: 14px;
		font-weight: 500;

		a {
			color: #def;

			&:hover {
				color: #fff;
			}
		}
	}

	&.full {
		padding: 0;
		border-radius: 0;
		box-shadow: none;

		& > .header {
			margin: 0;
			border-radius: 0;
			background: var(--bg);
			color: var(--deep);
		}
	}

	&.no-margin {
		border-radius: 0;
		padding: 0;

		& > .sc-panel {
			padding: 10px;
		}

		& > .header {
			border-radius: 0;
			margin: 0;
		}
	}

	&.scrollable > .sc-panel {
		overflow: auto;
	}

	& > .sc-panel-close-btn,
	& .header > .sc-panel-close-btn {
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		height: 40px;
		line-height: 40px;
		z-index: var(--panel-z) + 2;
		text-align: center;
		cursor: pointer;
		font-size: 14px;
		color: #888;
		opacity: 1;

		&:hover {
			color: var(--primary);
		}
	}

	&.with-header {
		& > .sc-panel {
			height: calc(100% - 40px);
		}

		& > .header .sc-panel-close-btn {
			color: #def;
			opacity: 0.7;

			&:hover {
				color: #def;
				opacity: 1;
			}
		}
	}

}

.sc-panel {
	width: 100%;
	height: 100%;
	padding: 10px 15px;
	background: var(--bg);

	&.full {
		border-radius: 0;
		padding: 10px 15px;
	}

	.form-field {
		//min-height: 50px;

		.sp-dropdown, .drop-down {
			min-width: 140px;
			width: calc(100% - 40px);
			//position: absolute;
		}
	}
}

@media (max-height: 900px) and (max-width: 1199px) {
	.sc-panel-wrapper.full {
		left: 100px !important;
	}
}
