#dnd-context {
	position: relative;

	.kb-draggable {
		position: absolute;
		pointer-events: none;
		user-select: none;
		left: -1000px;
		top: -1000px;
		width: var(--list-width, 240px);
		z-index: 2000;
		color: #000;
		cursor: pointer;
	}
}

.kb-container {
	font-size: 12px;
	position: relative;
	white-space: nowrap;
	user-select: none;
	//background: #eee;
	//height: calc(100% - 30px);
	height: 100%;
	overflow: auto;

	.debug {
		position: fixed;
		right: 0;
		top: 80px;
		z-index: 1000;
		padding: 5px 10px;
		background: rgba(#e0f0fa, 0.8);
		border: 1px solid #ccc;
		width: 400px;
		max-height: 80%;
		overflow: auto;

		.json {
			font-size: 11px;
			line-height: 1.1em;
		}
	}

	.kb-list {
		display: inline-block;
		width: var(--list-width, 240px);
		height: calc(100% + 0px);
		vertical-align: top;
		margin-right: 15px;
		overflow: hidden;
		position: relative;

		.hdr {
			height: 40px;
			line-height: 38px;
			display: flex;
			align-items: center;
			padding: 0 0 0 10px;
			border: 1px solid rgba(#000, 0.1);
			border-left: 3px solid #ccc;
			background: var(--list-hdr-bg);
			border-radius: 4px;

			.name {
				white-space: nowrap;
				font-weight: bold;
				overflow: hidden;
				text-overflow: ellipsis;
				flex-grow: 1;
			}

			.actions {
				flex-basis: 80px;
				flex-shrink: 0;
				text-align: right;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.count {
					margin-right: 5px;
				}

				.kb-btn {
					width: 24px;
					height: 24px;
					line-height: 24px;
					text-align: center;
					cursor: pointer;
					//background: #ccc;
					color: #555;

					&:hover {
						color: #0af;
					}
				}
			}
		}

		.kb-items {
			width: calc(var(--list-width, 240px) + 20px);
			height: calc(100% - 50px);
			padding-top: 5px;
			overflow: auto;
		}

		.kb-item {
			display: block;
			width: var(--list-width, 240px);
			text-decoration: none;
			color: #000;
			padding-top: 5px;
			padding-bottom: 5px;
			cursor: pointer;
			transition: all 250ms ease-in-out;

			/*
			* {
				pointer-events: none;
			}
			*/

			:not(.evented) {
				pointer-events: none;
			}

			.evented {
				* {
					pointer-events: all;
				}
			}

			// см. ниже
			.kb-item-content {
			}

			&.dragged {
				.kb-item-content {
					background: #e2e4e8;
					box-shadow: 0 0 0 rgba(#000, 0.15);

					* {
						//visibility: hidden;
					}
				}
			}

			&.loading {
				pointer-events: none;

				.kb-item-content {
					//border: 3px solid #ccc;
					//transform: scale(0.95, 0.95);
					opacity: 0.75;
					filter: saturate(0);
				}
			}

		}

		// см. ниже
		.kb-new-item {}

		.kb-loading-more {
			text-align: center;
			padding-top: 7px;
			height: 50px;
			display: flex;
			align-items: flex-start;
			justify-content: center;
		}

		.kb-list-info {
			position: absolute;
			bottom: 10px;
			left: 0;
			right: 0;
			z-index: 100;
			font-size: 11px;
			height: 40px;
			//background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
			background: linear-gradient(0deg, rgba(#fff, 1) 20%, rgba(#fff, 0) 100%);
			line-height: 11px;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			color: #888;
		}
	}
}

/** отдельно, чтобы легко можно было переписать правило */

.kb-item-content {
	background: #f8fafc;
	border: 1px solid rgba(#000, 0.15);
	border-left: 3px solid #ddd;
	box-shadow: 0 1px 2px rgba(#000, 0.15);
	word-break: break-word;
	overflow: hidden;
	white-space: pre-wrap;
	transition: all 250ms ease-in-out;

	* {
		word-break: break-word;
	}
}

.kb-new-item {
	display: block;
	margin-top: 5px;
	background: #f8fafc;
	color: #000;
	padding: 5px;
	border: 1px solid #ddd;
	border-left: 3px solid #ddd;
	box-shadow: 0 1px 2px rgba(#000, 0.15);
	transition: all 250ms ease-in-out;

	textarea {
		width: 100%;

		&:hover {
			border-color: #ccc;
		}
	}
}

.tasks-kanban .kb-container .kb-list .kb-list-info {
	background: linear-gradient(0deg, var(--content-bg) 20%, rgba(255, 255, 255, 0) 100%);
}
