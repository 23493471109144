.dictionaries {
	display: flex;
	gap: 20px;

	nav {
		flex-basis: 300px;
		flex-shrink: 0;
		padding-right: 15px;
		overflow: auto;

		.group {
			margin-bottom: 15px;
		}

		.title {

		}

		.models {
			list-style: disc;
			padding-left: 15px;

			a {
				display: block;
				line-height: 24px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	main {
		flex-grow: 1;
		overflow: auto;

		.dict-edit {
			max-width: 960px;
		}
	}
}
