.camera-popup {
	height: auto;
	.video-container {
		position: relative;
		width: 100%;
		margin-right: 5px;

		.controls {
			display: flex;
			.left {
				flex-grow: 1;
			}

			.right {
				text-align: right;

				.switch-btn {
					cursor: pointer;
					text-decoration: underline;
					color: #888;

					&:hover {
						color: var(--primary);
					}
				}
			}
		}
		.content {
			video {
				width: 100%;
				left: 0;
				top: 20px;
				background: #000;
				z-index: 100;
			}
		}

		.error {
			margin-top: 15px;
			color: #d00;
		}

		.loader {
			margin-top: -1px;
		}

		.status-point {
			display: inline-block;
			vertical-align: middle;
			width: 10px;
			height: 10px;
			background: #4c0;
			border-radius: 50%;
			margin-top: -1px;
			margin-right: 2px;

			&.error {
				background: #d00;
			}
		}
	}
}

.maximized-camera {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(#000, 0.9);
	z-index: 5000;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;

	.video-container {
		width: 100%;
		height: 100%;
		color: #fff;

		video {
			width: 100%;
			height: calc(100% - 50px);
			margin-top: 10px;
			border: 1px dotted rgba(#fff, 0.3);
		}

		.error {
			color: #f00;
		}

		.status-point {
			margin-right: 15px;
		}

		.loader {
			margin-left: 5px;
			margin-right: 10px;

			.lds-roller {
				transform: scale(0.3, 0.3) !important;
			}

			span {
				background: #ccc !important;
			}
		}

		.controls {
			.right {
				padding: 0;

				.switch-btn {
					color: #ddd;

					&:hover {
						color: var(--primary);
					}
				}
			}
		}
	}
}
