.role-edit {
	padding-bottom: 30px;

	.form-submit {
		display: none;
	}

	.actions {
		&.disabled {
			opacity: 0.5;
			filter: saturate(0);
			pointer-events: none;
		}

		.delete-btn {
			margin-left: 10px;
		}
	}
}

.acls {

	h2 {
		font-size: 14px;
	}

	.acl-table {
		border-collapse: collapse;
		margin-top: 15px;
		margin-bottom: 15px;
		font-size: 12px;

		tr.system td {
			background: #ffe8e8;
		}

		tr.all td {
			background: #fec;
		}

		th {
			text-align: left;
			font-weight: normal;
			font-size: 12px;
			background: #eee;
		}

		th, td {
			padding: 3px 5px;
			border: 1px solid #d0d0d0;
		}

		th:not(.model-name):not(.description), td:not(.model-name):not(.description) {
			width: 90px;
			text-align: center;
		}
		.description {
			width: 200px;
		}
	}
}
