.scale-line-container {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	bottom: 110px;
	width: 200px;
	height: 30px;
	z-index: 1000;
	user-select: none;
	//background: rgba(#fff, 0.5);
	transition: width 250ms ease-in-out;

	.scale-line {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 6px;
		//background: rgba(#d00, 0.5);
		width: 100%;
		border-bottom: 2px solid #000;
		border-right: 2px solid #000;
		border-left: 2px solid #000;
	}

	.scale-line-text {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		font-family: Roboto, sans-serif;
		font-size: 11px;
		height: 20px;
	}
}