@import "reset.css";
@import "hover.css";
@import "ui/index";

$primary: #37b813;
$primary-rgb: 55, 184, 19; // #37b813
$secondary: #1D46A1;
$secondary-rgb: 29, 70, 161; // #1D46A1
$light-blue-bg: #F2F6FC;
$bg: #FFFFFF;
$dark: #393E46;
$lighter-dark: #494e58;
$deep-dark: #2E3238;
$grey: #B8B8B8;
$dark-grey: #7A7A7A;
$light-grey: #EEEEEE;
$lighter-grey: #F9F9F9;
$light-accent: #EFF4F9;
$selected-bg: #c2eaa7;

:root {
	--primary: #{$primary};
	--primary-rgb: #{$primary-rgb};
	--secondary: #{$secondary};
	--secondary-rgb: #{$secondary-rgb};
	--bg: #{$bg};
	--light-blue-bg: #{$light-blue-bg};
	--dark: #{$dark};
	--lighter-dark: #{$lighter-dark};
	--deep-dark: #{$deep-dark};
	--grey: #{$grey};
	--dark-grey: #{$dark-grey};
	--light-grey: #{$light-grey};
	--lighter-grey: #{$lighter-grey};
	--light-accent: #{$light-accent};
	--selected-bg: #{$selected-bg};

	--header-height: 80px;
	--sidebar-width: 90px;
	--sidebar-btn-size: 80px;

	--hover: var(--lighter-grey);
	--border: var(--grey);
	--border-radius: 5px;
	--text: var(--dark);
	--panel-bg: var(--bg);

	--header-z: 3000;
	--sidebar-z: 2000;
	--panel-z: 1000;

	// covid

	--confirmed: #d00;
	--ambulatory: #90d;
	--isolated: #acf;
	--recovered: #6a0;
	--contacted: #f80;
	--infdep: #d40;
	--observator: #80a;
	--self: #0af;
}

html, body, #root {
	height: 100%;
}

body {
	font-family: 'Raleway', sans-serif;
	font-size: 14px;
	line-height: 1.4em;
	font-feature-settings: 'lnum';
}

.login-layout {
	width: 100%;
	height: 100%;
	background: var(--content-bg, #f2f2f4);
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.error {
	color: #d00;
}

a {
	color: var(--primary);

	&:hover{
		color: #000;
	};
}

hr {
	height: 1px;
	border: 0;
	background: #ddd;
	margin-bottom: 15px;
}

h1 {
	font-size: 16px;
	margin-bottom: 1em;
}

h2 {
	font-size: 14px;
	margin-bottom: 1em;
}

.login-form {
	margin: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: var(--main-bg, #fff);
	padding: 20px;
	border-radius: var(--border-radius, 4px);
	border: 1px solid rgba(#000, 0.15);


	.field {
		display: flex;
		margin-bottom: 5px;
		line-height: 30px;

		label {
			flex-basis: 80px;
		}

		input {
			width: 170px;
			height: 30px;
		}
	}

	.captcha, .submit {
		padding-left: 80px;
		margin-bottom: 10px;
	}

	.captcha {
		display: flex;
		align-items: center;

		img {
			border: 1px solid #ddd;
			width: 170px;
		}

		.reload {
			margin-left: 15px;
			cursor: pointer;

			&:hover * {
				fill: #0af !important;
			}
		}
	}

	.error {
		padding-left: 80px;
		font-size: 11px;
		color: #a00;
	}
}

input.monospaced {
	font-family: "Consolas", monospace;
}

.cursor-pointer {
	cursor: pointer;;
}

