.audit {
	$filters-height: 30px;
	display: flex;

	.audit-list {
		flex-grow: 1;
		overflow: hidden;

		& > .ui-pager {
			margin: 0 10px 6px 0;
		}

		.filters {
			height: $filters-height;
			//background: #ddd;
			margin-bottom: 10px;
			display: flex;
			align-items: center;

			.date-picker {
				width: 120px;
				min-width: 120px;
				margin-right: 10px;
			}

			.sp-dropdown, .drop-down {
				width: 140px;
				min-width: 140px;
				margin-right: 10px;

				.list {
					width: 320px;
				}

				.hint {
					color: #aaa;
				}
			}
		}

		.audit-headers {
			display: flex;
			align-items: center;
			height: 30px;
			margin-top: 10px;
			background: #eee;
			color: #888;
			font-size: 11px;
			padding: 0 5px;
		}

		.audit-headers, .audit-item {
			& > div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.date {
				flex-basis: 140px;
				flex-shrink: 0;
			}

			.model {
				flex-basis: 130px;
				flex-shrink: 0;
			}

			.description {
				flex-basis: 20%;
				flex-shrink: 0;
			}

			.owner {
				flex-grow: 1;
			}

			.action {
				flex-basis: 100px;
				flex-shrink: 0;
			}

			.data {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		.audit-items {
			height: calc(100% - #{$filters-height} - 40px);
			overflow: auto;
			margin-left: -5px;

			.audit-item {
				height: 29px;
				border-bottom: 1px solid rgba(#000, 0.15);
				display: flex;
				align-items: center;
				cursor: pointer;
				padding: 0 10px;
				border-radius: 4px;

				&:last-child {
					border-color: transparent;
				}

				&:hover {
					background: #e8f0f8;
				}

				.date {
					color: #888;
				}

				.description {
					span {
						color: #aaa;
					}
				}

				.data {
					overflow: hidden;
					white-space: nowrap;

					pre {
						color: #888;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 11px;
					}
				}
			}

		}

	}

	.audit-single {
		flex-basis: 450px;
		flex-shrink: 0;
		flex-grow: 0;
		overflow: auto;
		padding-left: 30px;
		padding-right: 15px;
		word-break: break-all;
		height: 100%;

		& > div {
			margin-bottom: 10px;

			label {
				display: block;
				font-size: 11px;
				color: #0af;
			}
		}

		.instance {
			overflow: auto;
			height: calc(100% - 200px);

			.data {
				background: rgba(#ff8, 0.3);
				border: 1px dotted rgba(#000, 0.15);
				padding: 10px 0;

				.property {
					padding-left: 10px;
					padding-right: 10px;
					padding-bottom: 5px;
					margin-bottom: 5px;
					border-bottom: 1px dotted rgba(#000, 0.5);

					&:last-child {
						border: 0;
						margin: 0;
						padding-bottom: 0;
					}

					label {
						color: #f80;
					}

					.hidden {
						color: #d00;
					}
				}
			}
		}
	}

	.action {
		&.delete {
			color: #d00;
		}

		&.update {
			color: #f80;
		}

		&.create {
			color: #0af;
		}
	}

}
